<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        justify-center
        wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="'plans'"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Manage Plans'"
                    :user_id="user_id"
                    :user_type="user_type"
                    :is-custom-filter="false"
                    :pre-selected-filters="filters.selectedFilters"
                    :isEdit="true"
        >
          <template slot="datatable-actions">
            <div class="action-buttons ml-3">
              <button class="btn btn-primary" @click="showPlanModal">{{ addPlanButtonText }}</button>
            </div>
          </template>
          <template slot="filters">
            <filters :filters="filters.getPlanTypeFilters"></filters>
          </template>
        </data-table>
      </template>


      <add-plan v-if="addPlanDialog"
                :visible="addPlanDialog"
                :action-type="action"
                :value="selectedPlan"
                :session_types_initial="initialSessionTypes"
                :session_types_ongoing="ongoingSessionTypes"
                v-on:submit="submitPlan"
                v-on:hide="hidePlanModal"
      ></add-plan>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "../../components/common/dataTable/DataTable";
import Filters from "../../components/common/dataTable/filters/Filters";
import AddPlan from "../../components/main/orgConsole/AddPlan";

export default {
  name: "OrgConsole",
  components: {
    DataTable,
    Filters,
    AddPlan
  },
  props: {
    user_id: {
      user_id: [Number, String]
    },
    user_type: {
      type: String
    },
    header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiurl: 'plans',
      search: {
        placeholder: 'Search',
        searchAble: false
      },
      sortOrder: [
        {
          field: 'id',
          sortField: 'id',
          direction: 'desc'
        }
      ],
      filters: [],
      selected_filters: [],
      loaded: false,
      action: 'add',
      addPlanButtonText: 'Create plan',
      addPlanDialog: false,
      selectedPlan: null,
      initialSessionTypes: [],
      ongoingSessionTypes: []
    }
  },
  created() {
    this.fetchFilters();
    this.getSessionTypes();
  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/plans').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
    removeSortFromUrl(key, sourceURL) {
      let rtn = sourceURL.split("?")[0],
          param,
          params_arr = [],
          queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      return rtn;
    },
    getSessionTypes() {
      this.initialSessionTypes = [];
      this.ongoingSessionTypes = [];
      this.$http.get(this.url + 'plan/session_types').then(response => {
        this.initialSessionTypes = response.data.initial_session_types;
        this.ongoingSessionTypes = response.data.ongoing_session_types;
      }).catch(error => {
      })
    },
    showPlanModal() {
      this.addPlanDialog = true;
    },
    hidePlanModal() {
      this.action = 'add';
      this.selectedPlan = null;
      this.addPlanDialog = false;
      this.getSessionTypes();
    },
    submitPlan() {
      this.action = 'add';
      this.selectedPlan = null;
      this.hidePlanModal();
      this.$events.fire('vuetable:reload');
    },
    hideDetailModal() {
      this.selectedPlan = null;
      this.planDetailDialog = false;
    }
  },
  events: {
    'edit-record': function (data) {
      this.action = 'edit';
      this.selectedPlan = data;
      this.showPlanModal();
    },
  },
}
</script>
